.prefix-item-container {
    animation-timing-function: ease;
    animation-fill-mode: backwards;
    animation-duration: .8s;
  }

.prefix-items{
  height: 80vh;
}

@media screen and (min-width: 1200px) {
  .prefix-items{
    height: 45vh;
  }
}