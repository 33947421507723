body {
  font-family: 'Nunito Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

/* fonts  */
.sen-font {
  font-family: 'Sen', sans-serif;
}
