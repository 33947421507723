.auth-page {
  background-color: var(--app-primary-color);
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px !important;

  .background-logo {
    position: absolute;
    top: calc(10% - 30px);
    left: 32px;
    width: 50%;
    height: 80%;
    > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      opacity: 0.2;
    }
  }

  .auth-card {
    width: 90%;
    max-width: 424px;
    position: relative;
    margin: auto;
  }

  .logo-container img {
    margin-bottom: 28px;
    width: 80%;
    max-width: 150px;
  }
}

.password-eye-icon {
  width: 24px;
  height: auto;
  margin-top: 5px;
}
